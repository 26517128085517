@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-Black.ttf) format("truetype");
  font-weight: 900; /* Black font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-ExtraBold.ttf) format("truetype");
  font-weight: 800; /* Extra bold font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-Bold.ttf) format("truetype");
  font-weight: bold; /* Bold font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-SemiBold.ttf) format("truetype");
  font-weight: 600; /* Semi bold font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-Medium.ttf) format("truetype");
  font-weight: 500; /* Medium font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-Regular.ttf) format("truetype");
  font-weight: normal; /* Regular font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-Light.ttf) format("truetype");
  font-weight: 300; /* Light font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-ExtraLight.ttf) format("truetype");
  font-weight: 200; /* Extra light font weight */
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./styles/fonts/Outfit-Thin.ttf) format("truetype");
  font-weight: 100; /* Thin font weight */
}

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /** google places autocomplete dropdown */
  .pac-container {
    z-index: 99999 !important;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: "100vh";
}
